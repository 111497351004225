import { createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    id: null,
    telegram_id: null,
    username: '',
    balance: 0,
    energy: 0,
    energy_boost_level: 0,
    tap_boost_level: 0,
    multi_tap_enabled: false,
    created_at: '',
    last_energy_update: '',
    last_login: '',
    enegry_refill_time: 0,
    is_new_user: false,
    current_league: 1,
    mining_level: 0,
    last_claim: 0,
    mining_claim_seconds: 0,
    login_streak: 0,
  },
  reducers: {
    setUser: (state, action) => {
      return { ...state, ...action.payload };
    },
    updateBalanceAndEnergy: (state, action) => {
      const { balance, energy } = action.payload;
      state.balance = balance;
      state.energy = energy;
    },
    updateEnergy: (state, action) => {
      state.energy = action.payload.energy;
    },
    updateBalance: (state, action) => {
      state.balance = action.payload.balance;
    },
    updateEnergyBoostLevel: (state, action) => {
      state.energy_boost_level = action.payload.energy_boost_level;
    },
    updateTapBoostLevel: (state, action) => {
      state.tap_boost_level = action.payload.tap_boost_level;
    },
    updateEnergyRefillTime: (state, action) => {
      state.enegry_refill_time = action.payload.enegry_refill_time;
    },
    updateMiningLevel: (state, action) => {
      state.mining_level = action.payload.mining_level;
    },
    updateMiningSecodns: (state, action) => {
      state.mining_claim_seconds = action.payload.mining_claim_seconds;
    },
    updateLeague: (state, action) => {
      state.current_league = action.payload.current_league;
    },
    updateStreak: (state, action) => {
      state.login_streak = action.payload.login_streak;
    },
    balancePlusOne: (state) => {
      state.balance++
    }
  },
});

// Экспортируем новые действия
export const { 
  setUser, 
  updateBalanceAndEnergy, 
  updateEnergy, 
  updateBalance, 
  updateEnergyBoostLevel, 
  updateTapBoostLevel,
  updateEnergyRefillTime, 
  updateMiningLevel,
  updateMiningSecodns,
  updateLeague,
  updateStreak,
  balancePlusOne,
} = userSlice.actions;

export default userSlice.reducer;