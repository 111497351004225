import classes from './Button.module.css'

const Button = ({blur, width, height, background, border, font, children, onClick }) => {
  return (
    <div 
      className={classes.Button} 
      style={{
        width: width, 
        height: height, 
        background: background, 
        borderRadius: border,
        fontSize: font
      }}

      onClick={onClick}
    >
      {blur && <div className={classes.blur} style={{background: background}}></div>}
      <span className={classes.text}>{children}</span>
    </div>
  )
}

export default Button;