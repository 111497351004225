import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  token: '',
  key: 0,
  error: false,
};

const tokenSlice = createSlice({
  name: 'token',
  initialState,
  reducers: {
    setToken(state, action) {
      state.token = action.payload;
    },
    setKey(state) {
      state.key = Date.now();
    },
    showError(state) {
      state.error = true
    },
    hideError(state) {
      state.error = false
    },
  },
});

export const { setToken, setKey, showError, hideError } = tokenSlice.actions;

export default tokenSlice.reducer;