import { useEffect, useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getLink, getReferals } from '../../../api/friendsApi';
import DecorativeTab from '../../ui/DecorativeTab/DecorativeTab';
import classes from './Friends.module.css';
import Button from '../../ui/Button/Button';
import StatsTab from '../../ui/StatsTab/StatsTab';
import UserTab from '../../ui/UserTab/UserTab';
import { setReferrals } from '../../../store/friendsSlice';
import { setLink } from '../../../store/linkSlice';
import { formatNumberToM } from '../../../utils';
import { useTranslation } from 'react-i18next';
import Spinner from '../../ui/Spinner/Spinner';

const Friends = () => {

  const token = useSelector((state) => state.token.token);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { loadedReferrals, totalFriends, premiumFriends } = useSelector((state) => state.friends);
  const link = useSelector((state) => state.link.link);

  const [copyMessage, setCopyMessage] = useState(''); // Состояние для сообщения о копировании

  const fetchLink = useCallback(async () => {
    try {
      if (link === '') {  // Проверяем, что ссылка еще не загружена
        const response = await getLink(token);
        const invite_link = response.invite_link;
        if (invite_link) {
          dispatch(setLink(invite_link));
        }
      }
    } catch (error) {
      console.error("Ошибка при получении ссылки:", error);
    }
  }, [token, link, dispatch]);
  
  const fetchUsers = useCallback(async () => {
    try {
      if (loadedReferrals === 0) {  // Проверяем, что данные о рефералах еще не загружены
        const response = await getReferals(token);
        const users = response.referrals;
        if (users) {
          const sortedUsers = users.sort((a, b) => b.balance - a.balance); // Сортируем по балансу
          dispatch(setReferrals(sortedUsers));
        }
      }
    } catch (error) {
      console.error("Ошибка при получении списка рефералов:", error);
    }
  }, [token, loadedReferrals, dispatch]);
  
  // Вызов fetchLink при монтировании компонента и изменении зависимостей
  useEffect(() => {
    fetchLink();
  }, [fetchLink]);
  
  // Вызов fetchUsers при монтировании компонента и изменении зависимостей
  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  const copyLink = () => {
    navigator.clipboard.writeText(link);
    setCopyMessage(t('friends.ctc')); // Установить сообщение
    setTimeout(() => {
      setCopyMessage(''); // Очистить сообщение через 2 секунды
    }, 2000);
  };

  const sendLink = () => {
    const tg = window.Telegram ? window.Telegram.WebApp : null;
    const text = t('friends.welcome');
    tg.openTelegramLink(`https://t.me/share/url?url=${link}&text=${encodeURI(text)}`);
  };

  const memoizedReferrals = useMemo(() => (
    loadedReferrals !== 0 && loadedReferrals.slice(0, 20).map((referral) => (
      <UserTab
        key={referral.id}
        img={referral.profile_photo_url ? referral.profile_photo_url : '/assets/defaultAva.png'}
        name={referral.username}
        income={formatNumberToM(referral.balance)}
        rightTitle={t('friends.yr')}
        rightContent={referral.earnings}
      />
    ))
  ), [loadedReferrals]);

  return (
    <div className={classes.Friends}>
      <div className={classes.container}>
        <div className={classes.relative}>
          <DecorativeTab 
            width={'83px'} 
            height={'31px'} 
            color={'#00FF88'} 
            bgcolor={'rgba(0, 255, 136, 0.20)'}
            radius={'12px'}
            font={'15px'}
            position={{top: '-31px', left: '-95px'}}
            main={true}
          >
            {'+1 teman'}
          </DecorativeTab>
          <DecorativeTab 
            width={'71px'} 
            height={'26px'} 
            color={'#00FF88'} 
            bgcolor={'rgba(0, 255, 136, 0.20)'}
            radius={'10px'}
            font={'13px'}
            position={{bottom: '-26px', left: '-90px'}}
            main={true}
          >
            {'+1 teman'}
          </DecorativeTab>
          <DecorativeTab 
            width={'99px'} 
            height={'37px'} 
            color={'#00FF88'} 
            bgcolor={'rgba(0, 255, 136, 0.20)'}
            radius={'14px'}
            font={'18px'}
            position={{top: '-25px', right: '-95px'}}
            main={true}
          >
            {'+1 teman'}
          </DecorativeTab>

          <h1 className={classes.title}>
            <span className={classes.gradient}>{t('friends.iy')}<br/>{t('friends.f')}</span>
            <span className={classes.shadow}>{t('friends.iy')}<br/>{t('friends.f')}</span>
            {t('friends.iy')}<br/>{t('friends.f')}
          </h1>
        </div>
        <div className={classes.buttonContainer}>
          <Button
            blur={true}
            width={'141px'}
            height={'35px'}
            background={'#00FF88'}
            border={'9px'}
            font={'18px'}
            onClick={sendLink}
          >
            {t('friends.inv')}
            <img src={'/assets/inviteButton.svg'} alt='invite' className={classes.buttonImg}/>
          </Button>
          <div className={classes.copyButton}>
            <Button
              blur={true}
              width={'35px'}
              height={'35px'}
              background={'#00FF88'}
              border={'9px'}
              font={'18px'}
              onClick={copyLink}
            >
              <div className={classes.copy}>
                <img src={'/assets/copy.svg'} alt='copy'/>
              </div>
            </Button>
          </div>
        </div>
        {copyMessage && <div className={classes.copyMessage}>{copyMessage}</div>} {/* Вывод сообщения */}
        <div className={classes.stats}>
          <StatsTab title={t('friends.tf')} stat={memoizedReferrals ? totalFriends : <Spinner width={'10px'} height={'10px'} b={'1.6px solid #ffffff'} bt={'1.6px solid #00ff88'} margin={0}/>}/>
          <StatsTab title={t('friends.p')} stat={memoizedReferrals ? premiumFriends: <Spinner width={'10px'} height={'10px'} b={'1.6px solid #ffffff'} bt={'1.6px solid #00ff88'} margin={0}/>}/>
          <StatsTab title={t('friends.d')} stat={memoizedReferrals ? totalFriends - premiumFriends : <Spinner width={'10px'} height={'10px'} b={'1.6px solid #ffffff'} bt={'1.6px solid #00ff88'} margin={0}/>}/>
        </div>
        <div className={classes.friendsList}>
          <div className={classes.gap}>
            {memoizedReferrals ? memoizedReferrals : <Spinner/>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Friends;
