import { combineReducers } from 'redux';
import userReducer from './userSlice'
import leagueReducer from './leagueSlice'
import friendsReducer from './friendsSlice'
import linkReducer from './linkSlice';
import tasksReducer from './tasksSlice';
import rewardReducer from './rewardSlice';
import tokenReducer from './tokenSlice';

const appReducer = combineReducers({
  user: userReducer,
  league: leagueReducer,
  friends: friendsReducer,
  link: linkReducer,
  tasks: tasksReducer,
  reward: rewardReducer,
  token: tokenReducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'RESET_STATE') {
    // Если действие сброса, устанавливаем состояние на undefined,
    // чтобы все редьюсеры вернулись к своему initialState
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
