import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loadedLeagues: {},
};

const leagueSlice = createSlice({
  name: 'league',
  initialState,
  reducers: {
    setLoadedLeagues(state, action) {
      state.loadedLeagues = action.payload;
    },
    addLeagueData(state, action) {
      const { leagueKey, users } = action.payload;
      state.loadedLeagues[leagueKey] = users;
    },
  },
});

export const { setLoadedLeagues, addLeagueData } = leagueSlice.actions;

export default leagueSlice.reducer;