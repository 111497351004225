import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isOpen: false
};

const rewardSlice = createSlice({
  name: 'reward',
  initialState,
  reducers: {
    toggleReward(state) {
      state.isOpen = !state.isOpen;
    },
  },
});

export const { toggleReward } = rewardSlice.actions;

export default rewardSlice.reducer;