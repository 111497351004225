
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loadedReferrals: 0,
  totalFriends: 0,
  premiumFriends: 0,
};

const friendsSlice = createSlice({
  name: 'friends',
  initialState,
  reducers: {
    setReferrals(state, action) {
      const referrals = action.payload;
      console.log('ref', referrals)
      state.loadedReferrals = referrals;
      state.totalFriends = referrals.length;
      state.premiumFriends = referrals.filter(referral => referral.is_premium).length;
    },
  },
});

export const { setReferrals } = friendsSlice.actions;
export default friendsSlice.reducer;
