import classes from './StatsTab.module.css'

const StatsTab = ({ title, stat }) => {
  return (
    <div className={classes.StatsTab}>
      <div className={classes.shadow}></div>
      <p className={classes.title}>{title}</p>
      <p className={classes.stat}>{stat}</p>
    </div>
  )
}

export default StatsTab;