import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { WebAppProvider } from '@vkruglikov/react-telegram-web-app';
import { auth, authorizeUser, getUser } from './api/userApi';
import { Provider } from 'react-redux';
import { store } from './store/store'
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import NoPhone from './components/pages/NoPhone/NoPhone';
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://800325479abe929ed488abb8a0904475@o4508194296496128.ingest.de.sentry.io/4508194298527824",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});



const isMobileDevice = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  //True, если мобильное
};

const isTouchDevice = () => {
  return 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
  //True, если поддерживает тапы
};

const tg = window.Telegram ? window.Telegram.WebApp : null;
const platform = window.Telegram.WebApp.platform;
console.log(platform)

if (tg) {
  tg.expand();
  tg.ready();
  tg.isClosingConfirmationEnabled = true; // Включаем подтверждение перед закрытием
}

const data = window.Telegram.WebApp.initData;
const params = new URLSearchParams(data);
const user = JSON.parse(params.get('user'))?.id;
console.log(user)

const allowPc = () => {
  if (user === 1032198799 || user === 746919752 || user === 466105964) {
    return true;
  }

  const isMobilePlatform = platform === 'android' || platform === 'ios';
  if (isMobilePlatform && isMobileDevice() && isTouchDevice()) {
    return true;
  }

  return false;
};

const root = ReactDOM.createRoot(document.getElementById('root'));
  root.render(
    <React.StrictMode>
      <WebAppProvider>
        <Provider store={store}>
          <I18nextProvider i18n={i18n}>
          {allowPc() ?
            <App />
            :
            <NoPhone/>
          }
          </I18nextProvider>
        </Provider>
      </WebAppProvider>
    </React.StrictMode>
);