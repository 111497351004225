import { useEffect, useState } from 'react';
import DailyTab from '../../ui/DailyTab/DailtyTab';
import classes from './DailyModal.module.css'
import { useSelector } from 'react-redux';

const DailyModal = ({ onClose }) => {
  const [days, setDays] = useState([]);
  const user = useSelector((state) => state.user);
  console.log(user.login_streak)
  useEffect(() => {
    dayBuilder();
  }, []);

  const dayBuilder = () => {
    const newDays = [];
    for (let i = 1; i <= 30; i++) {
      newDays.push({
        day: i,
        reward: 500 * i,
        unactive: i < user.login_streak,
        active: i === user.login_streak
      });
    }
    setDays(newDays);
  };

  return (
    <div className={classes.DailyModal} onClick={onClose}>
      <div className={classes.modalContent} onClick={(e) => e.stopPropagation()}>
        <div className={classes.days}>
          {days.map((item, index) => (
            <DailyTab
              key={index}
              day={item.day}
              reward={item.reward}
              unactive={item.unactive}
              active={item.active}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default DailyModal;