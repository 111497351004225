import { useEffect, useState, useRef } from 'react';
import { decode } from './userApi';
import { useDispatch, useSelector } from 'react-redux';
import { updateBalance, updateBalanceAndEnergy, updateEnergy, updateLeague } from '../store/userSlice';  // Импортируйте экшен

export const useTapSocket = (token) => {
  const dispatch = useDispatch(); // Используем dispatch для вызова экшенов
  const [message, setMessage] = useState('');
  const ws = useRef(null);
  const debounceTimeoutRef = useRef(null);
  
  useEffect(() => {
    if (!token) return;

    const wsUrl = `wss://ws.gekkogame.io/ws/${decode(token)}/${token}`;
    ws.current = new WebSocket(wsUrl);

    ws.current.onopen = () => {
      console.log("Connected to WebSocket");
      const taps = localStorage.getItem('taps')
      if (taps > 0) {
        sendTap(taps)
        localStorage.setItem('taps', JSON.stringify(0))
      }
    };

    ws.current.onmessage = (event) => {
      const data = event.data;
      console.log("Message from server:", data);
      if (data.startsWith("balance")) {
        const [balanceText, energyText, leagueText] = data.split(", ");
        const newBalance = parseFloat(balanceText.split(": ")[1]);
        const remainingEnergy = parseInt(energyText.split(": ")[1]);
        const currentLeague = parseInt(leagueText.split(': ')[1]);
        
        dispatch(updateBalance({ balance: newBalance }));
        dispatch(updateEnergy({ energy: remainingEnergy }));
        dispatch(updateLeague({ current_league: currentLeague }));
      } else {
        setMessage(data); // Обработка других сообщений (например, ошибок)
      }
    };

    ws.current.onclose = () => {
      console.log("WebSocket connection closed");
    };

    return () => {
      ws.current.close();
    };
  }, [token, dispatch]);

  const sendTap = (value) => {
    if (ws.current && ws.current.readyState === WebSocket.OPEN) {
      ws.current.send(value); // Отправляем сообщение серверу
    }
  };

  return {
    message,
    sendTap,
  };
};