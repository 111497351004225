import classes from './Social.module.css'
import SmallTab from '../../ui/SmallTab/SmallTab';
import telegram from '../../../assets/svgs/telegram-icon.svg'
import tg from '../../../assets/svgs/tg.svg'
import yt from '../../../assets/svgs/yt.svg'
import x from '../../../assets/svgs/x.svg'
import insta from '../../../assets/svgs/insta.png'
import tiktok from '../../../assets/svgs/tiktok.png'

import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ActionModal from '../../modals/ActionModal/ActionModal';
import { updateButtonState } from '../../../store/tasksSlice';
import { checkLinkTask, checkSpecTask, checkTask, goLink, socialCheck } from '../../../api/taskApi';
import { updateBalance } from '../../../store/userSlice';
import { getLink } from '../../../api/friendsApi';
import { setLink } from '../../../store/linkSlice';
import { useTranslation } from 'react-i18next';
import Button from '../../ui/Button/Button';

const Social = ({close, id, tasks, titleTop, titleBot }) => {
  const token = useSelector((state) => state.token.token);

  const { t } = useTranslation();
  const [modalOpen, setModalOpen] = useState(false)
  const [modalContent, setModalContent] = useState()
  const { loadedTasks, dailyTasks, specialTasks, buttonsState } = useSelector((state) => state.tasks);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch()
  const link = useSelector((state) => state.link.link);
  const [completed, setCompleted] = useState(false)

  useEffect(() => {
    const filteredButtonStates = buttonsState.filter(bs => tasks.some(t => t.id === bs.id));
    const allCompleted = filteredButtonStates.every(bs => bs.status === "completed");
    setCompleted(allCompleted)
  }, [buttonsState])

  const fetchLink = useCallback(async () => {
    console.log(link)
    if (link === '') {
      const response = await getLink(token);
      const invite_link = response.invite_link;
      dispatch(setLink(invite_link));
    }
  }, [token, link]);

  useEffect(() => {
    fetchLink();
  }, [fetchLink]);


  const showAction = (token, img, titleTop, titleBot, id) => {
    setModalContent(
      <ActionModal
        token={token} 
        onClose={() => setModalOpen(false)} 
        img={img} 
        titleTop={titleTop} 
        titleBot={titleBot} 
        id={id}/>)
    setModalOpen(true)
  }

  const showSocialAction = (token, img, titleTop, titleBot, id) => {
    setModalContent(
      <ActionModal
        token={token} 
        onClose={() => setModalOpen(false)} 
        img={img} 
        titleTop={titleTop} 
        titleBot={titleBot} 
        id={id}
        buttonContent={'Back'}
        onClick={() => setModalOpen(false)}
        />)
    setModalOpen(true)
  }

  const onLinkButtonClick = async(id, token) => {
    const currentState = buttonsState.find(item => item.id === id); // Находим состояние кнопки по id
    const currentTask = loadedTasks.find(item => item.id === id); // Находим задачу по id
    const tg = window.Telegram ? window.Telegram.WebApp : null;
    if (currentState?.status === 'not_completed') {
      // Открываем ссылку, если задача ещё не началась
      tg.openLink(currentTask.link);
      const response = await checkLinkTask(id, token)
      dispatch(updateButtonState({ id: id, status: 'completed'}));
    } else if (currentState?.status === 'ready_to_claim') {
      const response = await checkLinkTask(id, token)
      dispatch(updateBalance({ balance: Math.floor(response[0].new_balance)}))
      dispatch(updateButtonState({ id: id, status: 'completed'}));
    }
  }

  const onInstButtonClick = async(id, token) => {
    const currentState = buttonsState.find(item => item.id === id); // Находим состояние кнопки по id
    const currentTask = loadedTasks.find(item => item.id === id); // Находим задачу по id
    const tg = window.Telegram ? window.Telegram.WebApp : null;
    if (currentState?.status === 'not_completed') {
      // Открываем ссылку, если задача ещё не началась
      tg.openLink(currentTask.link);
      await goLink(id, token);
      dispatch(updateButtonState({ id: id, status: 'in_progress'}));
    } else if (currentState?.status === 'in_progress') {
      const response = await socialCheck(id, token)
      if (response.status === 'in_progress') {
        showSocialAction(token, currentTask.task_type === 'insta' ? insta : currentTask.task_type === 'tiktok' ? tiktok : telegram, "Task under review", "please check back later", id)
      } else {
        dispatch(updateBalance({ balance: Math.floor(response.new_balance)}))
        dispatch(updateButtonState({ id: id, status: 'completed'}));
      }
    }
  }

  const onTiktokButtonClick = async(id, token) => {
    const currentState = buttonsState.find(item => item.id === id); // Находим состояние кнопки по id
    const currentTask = loadedTasks.find(item => item.id === id); // Находим задачу по id
    const tg = window.Telegram ? window.Telegram.WebApp : null;
    if (currentState?.status === 'not_completed') {
      // Открываем ссылку, если задача ещё не началась
      tg.openLink(currentTask.link);
      await goLink(id, token);
      dispatch(updateButtonState({ id: id, status: 'in_progress'}));
    } else if (currentState?.status === 'in_progress') {
      const response = await socialCheck(id, token)
      if (response.status === 'in_progress') {
        showSocialAction(token, currentTask.task_type === 'insta' ? insta : currentTask.task_type === 'tiktok' ? tiktok : telegram, "Task under review", "please check back later", id)
      } else {
        dispatch(updateBalance({ balance: Math.floor(response.new_balance)}))
        dispatch(updateButtonState({ id: id, status: 'completed'}));
      }
    }
  }

  const onStoryButtonClick = async(id, token) => {
    const currentState = buttonsState.find(item => item.id === id); // Находим состояние кнопки по id
    const currentTask = loadedTasks.find(item => item.id === id); // Находим задачу по id
    const tg = window.Telegram ? window.Telegram.WebApp : null;
    if (currentState?.status === 'not_completed') {
      // Открываем ссылку, если задача ещё не началась
      tg.shareToStory(currentTask.link, {text: `${currentTask.description}\n ${link}`})
      await goLink(id, token);
      dispatch(updateButtonState({ id: id, status: 'in_progress'}));
    } else if (currentState?.status === 'in_progress') {
      const response = await socialCheck(id, token)
      if (response.status === 'in_progress') {
        showSocialAction(token, currentTask.task_type === 'insta' ? insta : currentTask.task_type === 'tiktok' ? tiktok : telegram, "Task under review", "please check back later", id)
      } else {
        dispatch(updateBalance({ balance: Math.floor(response.new_balance)}))
        dispatch(updateButtonState({ id: id, status: 'completed'}));
      }
    }
  }

  const onSubButtonClick = async (id, token) => {
    const currentState = buttonsState.find(item => item.id === id); // Находим состояние кнопки по id
    const currentTask = loadedTasks.find(item => item.id === id); // Находим задачу по id
    const tg = window.Telegram ? window.Telegram.WebApp : null;
  
    if (currentState?.status === 'not_completed') {
      // Открываем ссылку, если задача ещё не началась
      tg.openTelegramLink(currentTask.link);
      dispatch(updateButtonState({ id: id, status: 'started' })); // Обновляем статус на 'started'
    } else if (currentState?.status === 'started') {
      // Проверяем задачу, если она в статусе 'started'
      const response = await checkTask(id, token);
      if (response[0].status === 'success') {
        dispatch(updateButtonState({ id: id, status: 'completed' })); // Обновляем статус на 'ready_to_claim'
      } else {
        showAction(token, telegram, currentTask.title, currentTask.description, id)
      }
    } else if (currentState?.status === 'ready_to_claim') {
      dispatch(updateBalance({ balance: +user.balance + +Math.floor(currentTask.reward)}))
      dispatch(updateButtonState({ id: id, status: 'completed' }));
    } else if (currentState?.status === 'completed') {
      console.log('claimed'); // Если задача уже выполнена
    }
  };

  const taskClickHandler = (id, token) => {
    const currentTask = loadedTasks.find(item => item.id === id);
    switch (currentTask?.task_type) {
      case 'subscribe':
        onSubButtonClick(id, token)
        break;

      case 'link':
        onLinkButtonClick(id, token)
        break;

      case 'insta':
        onInstButtonClick(id, token)
        break;

      case 'tiktok':
        onTiktokButtonClick(id, token)
        break;
    
      case 'story':
        onStoryButtonClick(id, token)
        break;
      default:
        break;
    }
  }
  
  const subButtonContent = (id) => {
    const currentState = buttonsState.find(item => item.id === id); // Находим текущее состояние кнопки
  
    // Возвращаем текст для кнопки в зависимости от состояния
    switch (currentState?.status) {
      case 'not_completed':
        return t('earn.start');
      case 'in_progress':
        return t('earn.check');
      case 'started':
        return t('earn.check');
      case 'ready_to_claim':
        return t('earn.claim');
      case 'completed':
        return t('earn.done');
      default:
        return 'Unknown';
    }
  };
  
  const memoizedTasks = useMemo(() => (
    [...tasks]
    .filter(task => buttonsState.find(item => item.id === task.id)?.status !== 'completed') // Фильтрация по статусу
    .sort((a, b) => b.reward - a.reward)
    .map((task) => (
      <SmallTab
        key={task.id}
        img={task.task_type === 'insta' ? insta : task.task_type === 'tiktok' ? tiktok : telegram}
        titleTop={task.title}
        titleBot={task.task_type === 'story' ? " " : task.description}
        buttonContent={subButtonContent(task.id)} // Текст кнопки в зависимости от состояния задачи
        onClick={() => taskClickHandler(task.id, token)} // Обработчик клика для кнопки
        opacity={buttonsState.find(item => item.id === task.id)?.status === 'completed' ? '60%' : '100%'} // Если задача завершена, делаем прозрачность 60%
      />
    ))
  ), [tasks, buttonsState]);

  const handleComplete = async (id, token) => {
    const response = await checkSpecTask(id, token)
    if (response[0].status === 'success') {
      dispatch(updateBalance({ balance: Math.floor(response[0].new_balance)}))
      dispatch(updateButtonState({ id: id, status: 'completed' }))
      close()
    }
  }
  
  return (
    <div className={classes.Social}>
      <div className={classes.modal}>
        {modalOpen && modalContent}
      </div>
      <div className={classes.banner}>
        <div className={classes.socials}>
          <div className={classes.imgs}>
            <img src={tg} alt='tg'/>
            <img src={yt} alt='yt'/>
            <img src={x} alt='x'/>
          </div>
          <div className={classes.cross} onClick={close}>
            &times;
          </div>
        </div>
        <div className={classes.title}>
          {titleTop}<br/>{titleBot}
        </div>
      </div>
      <div className={classes.container}>
        <div className={classes.tasks}>
          <div className={classes.task}>
            {memoizedTasks}
          </div>
        </div>
      </div>
      <div
        style={{opacity: completed ? '100%' : '50%'}}
        className={classes.rewardButton}
        onClick={completed ? () => handleComplete(id, token) : () => {}}
      >
        MENDAPATKAN HADIAH
      </div>
    </div>
  )
}

export default Social;