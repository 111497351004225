import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './locales/en.json';
import ind from './locales/ind.json';


i18n
  .use(initReactI18next) // подключаем react-i18next
  .init({
    resources: {
      en: { translation: en },
      ind: { translation: ind },
    },
    lng: 'ind', // Язык по умолчанию
    fallbackLng: 'ind', // Резервный язык
    interpolation: {
      escapeValue: false, // React уже делает экранирование
    },
  });

export default i18n;