import classes from './ClickBubble.module.css'

const ClickBubble = ({ text, x, y, onAnimationEnd }) => {
  return (
    <div
      className={classes.ClickBubble}
      style={{ top: y, left: x }}
      onAnimationEnd={onAnimationEnd}
    >
      {text}
    </div>
  );
};

export default ClickBubble;