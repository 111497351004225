import classes from './DailyTab.module.css'
import { useTranslation } from 'react-i18next';

const DailyTab = ({ day, reward, unactive, active }) => {
  const { t } = useTranslation();
  return (
    <div className={classes.DailyTab} style={{opacity: `${unactive ? '0.5' : '1'}`}}>
      <div className={classes.shadow}></div>

      <div className={classes.left}
        style={{
          fontWeight: active ? 'bold' : 'normal',
          color: active ? '#00ff88' : 'white' // или любой другой цвет по умолчанию
        }}>

        {`${t('daily.d')} ${day}`}
      </div>

      <div className={classes.right}
        style={{
          fontWeight: active ? 'bold' : 'normal',
          color: active ? '#00ff88' : 'white' // или любой другой цвет по умолчанию
        }}>
        {reward}
      </div>

    </div>
  )
}

export default DailyTab;