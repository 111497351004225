import classes from './RewardModal.module.css'
import { useSelector, useDispatch } from 'react-redux';
import gif from '../../../assets/gifs/bum2.gif'
import { toggleReward } from '../../../store/rewardSlice';

const RewardModal = () => {

  const streak = useSelector((state) => state.user.login_streak);
  const dispatch = useDispatch()

  return (
    <div className={classes.ActionModal} onTouchEnd={(e) => e.stopPropagation()} onClick={() => dispatch(toggleReward())}>
      <div className={classes.modalContent} onTouchEnd={(e) => e.stopPropagation()} onClick={(e) => e.stopPropagation()}>
        <div className={classes.days}>
          <h1 className={classes.title}>
            <span className={classes.gradient}>Hari cek-in: {streak}</span>
            <span className={classes.shadow}>Hari cek-in: {streak}</span>
            Hari cek-in: {streak}
          </h1>
        </div>
        <h1 className={classes.title}>
            <span className={classes.gradient}>Hadiah kamu: {streak * 500}</span>
            <span className={classes.shadow}>Hadiah kamu: {streak * 500}</span>
            Hadiah kamu: {streak * 500}
          </h1>
        <div className={classes.button} onTouchEnd={(e) => e.stopPropagation()} onClick={() => dispatch(toggleReward())}>
          Hebat!
        </div>
        <img src={'/assets/bum2.gif'} alt={'firework'} className={classes.gif}/>
      </div>
    </div>
  )
}

export default RewardModal;