import axios from "axios";
import { decode } from "./userApi";
import { path } from "./path";
import { store } from "../store/store";
import { setKey, showError } from "../store/tokenSlice";

export const getTimeToRefill = async (token) => {
  try {
    const response = await axios.get(
      `${path}/energy/refill-time/${decode(token)}`, 
      {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );
    
    return response.data;

  } catch (err) {
    console.error('Ошибка при полчучении времени восполнения энергии', err);
    if (err.response && (err.response.status === 401 || err.response.status === 403)) {
      store.dispatch(showError())
      setTimeout(() => {
        store.dispatch({ type: 'RESET_STATE' });
        store.dispatch(setKey(Date.now()));
      }, 3000)
    }
    return null;
  }
};

export const refillEnergy = async (token) => {
  try {
    const response = await axios.post(
      `${path}/energy/restore/${decode(token)}`, 
      {},
      {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );
    
    return response.data;

  } catch (err) {
    console.error('Ошибка при восполнении энергии', err);
    if (err.response && (err.response.status === 401 || err.response.status === 403)) {
      store.dispatch(showError())
      setTimeout(() => {
        store.dispatch({ type: 'RESET_STATE' });
        store.dispatch(setKey(Date.now()));
      }, 3000)
    }
    return null;
  }
};

export const upgradeMultitap = async (token) => {
  try {
    const response = await axios.post(
      `${path}/boosts/multi-tap/${decode(token)}`, 
      {},
      {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );
    
    return response.data;

  } catch (err) {
    console.error('Ошибка при повышении уровня мультитап', err);
    if (err.response && (err.response.status === 401 || err.response.status === 403)) {
      store.dispatch(showError())
      setTimeout(() => {
        store.dispatch({ type: 'RESET_STATE' });
        store.dispatch(setKey(Date.now()));
      }, 3000)
    }
    return null;
  }
};

export const upgradeEnergy = async (token) => {
  try {
    const response = await axios.post(
      `${path}/boosts/energy/${decode(token)}`, 
      {},
      {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );

    return response.data;

  } catch (err) {
    console.error('Ошибка при повышении уровня энергии', err);
    if (err.response && (err.response.status === 401 || err.response.status === 403)) {
      store.dispatch(showError())
      setTimeout(() => {
        store.dispatch({ type: 'RESET_STATE' });
        store.dispatch(setKey(Date.now()));
      }, 3000)
    }
    return null;
  }
};

export const upgradeMining = async (token) => {
  try {
    const response = await axios.post(
      `${path}/mining/upgrade_mining/${decode(token)}`, 
      {},
      {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );

    return response.data;

  } catch (err) {
    console.error('Ошибка при повышении уровня майнинга', err);
    if (err.response && (err.response.status === 401 || err.response.status === 403)) {
      store.dispatch(showError())
      setTimeout(() => {
        store.dispatch({ type: 'RESET_STATE' });
        store.dispatch(setKey(Date.now()));
      }, 3000)
    }
    return null;
  }
};

export const claim = async (token) => {
  try {
    const response = await axios.post(
      `${path}/mining/claim/${decode(token)}`, 
      {},
      {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );

    return response.data;

  } catch (err) {
    console.error('Ошибка при повышении уровня майнинга', err);
    if (err.response && (err.response.status === 401 || err.response.status === 403)) {
      store.dispatch(showError())
      setTimeout(() => {
        store.dispatch({ type: 'RESET_STATE' });
        store.dispatch(setKey(Date.now()));
      }, 3000)
    }
    return null;
  }
};