import React, { useState, useEffect, useRef } from 'react';
import './App.css';
import Loading from './components/pages/Loading/Loading'
import Home from './components/pages/Home/Home';
import Menu from './components/ui/Menu/Menu';
import League from './components/pages/League/League';
import { useWebApp } from '@vkruglikov/react-telegram-web-app';
import { useDispatch, useSelector } from 'react-redux';
import { setUser, updateBalance, updateEnergy, updateMiningSecodns, updateStreak } from './store/userSlice';
import { getToken, getUser } from './api/userApi';
import { useTapSocket } from './api/tapApi';
import Onboarding from './components/modals/Onboarding/Onboarding';
import { dailyReward } from './api/taskApi';
import { useTranslation } from 'react-i18next';
import { toggleReward } from './store/rewardSlice';
import { setToken } from './store/tokenSlice';

function App() {
  const dispatch = useDispatch()
  const token = useSelector((state) => state.token.token);
  const WebApp = useWebApp();
  const [onboadingContent, setOnboardingContent] = useState(0)
  const [loading, setLoading] = useState(true)
  const [fetching, setFetching] = useState(true)
  const { message, sendTap } = useTapSocket((token))
  const [activeTab, setActiveTab] = useState(<Home sendTap={sendTap}/>)
  const [activeIndex, setActiveIndex] = useState(0);
  const user = useSelector((state) => state.user);
  const { t, i18n } = useTranslation();
  const key = useSelector((state) => state.token.key);
  const isError = useSelector((state) => state.token.error);

  useEffect(() => {
    setLoading(true)
    setFetching(true)
    setActiveTab(<Home sendTap={sendTap}/>)
    setActiveIndex(0)
    setOnboardingContent(0)
  }, [key])

  
  useEffect(() => {
    if (user.mining_level) {
      const interval = setInterval(() => {
        if (user.mining_claim_seconds < 28800) {
          dispatch(updateMiningSecodns({ mining_claim_seconds: user.mining_claim_seconds + 0.1 }));
        }
      }, 100);
  
      return () => clearInterval(interval);
    }
  }, [user.mining_level, user.mining_claim_seconds, dispatch, key]);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
      setOnboardingContent(1)
    }, 3000)
  }, [key])

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Получаем токен и сохраняем его
        const response = await getToken();
        const token = response?.access_token;
        dispatch(setToken(token));
  
        // Устанавливаем активную вкладку с переданными данными
        setActiveTab(<Home sendTap={sendTap} isLoading={loading} />);
  
        if (token) {
          const userData = await getUser(token);
          dispatch(setUser(userData));
    
          // Запрашиваем ежедневную награду и обновляем состояние
          const rewardData = await dailyReward(token);
          dispatch(updateBalance({ balance: Number(rewardData?.balance) }));
          dispatch(updateStreak({ login_streak: rewardData.login_streak }));
          
          // Проверяем условия для отображения награды
          if (rewardData.rewarded && user.is_new_user === false) {
            dispatch(toggleReward());
          }
        }
      } catch (error) {
        console.error("Ошибка при загрузке данных:", error);
      } finally {
        setFetching(false);
      }
    };
  
    fetchData();
  }, [key]);

  useEffect(() => {
    if (user.energy < user.energy_boost_level) {
      const interval = setInterval(() => {
        const newEnergy = user.energy + 3;
        if (newEnergy <= user.energy_boost_level) {
          dispatch(updateEnergy({ energy: newEnergy }));
        } else {
          dispatch(updateEnergy({ energy: user.energy_boost_level }));
          clearInterval(interval);
        }
      }, 1000);
  
      return () => clearInterval(interval);
    }
  }, [dispatch, user.energy, user.energy_boost_level, key]);
  
  const onboardingComplete = () => {
    if (onboadingContent >= 6) {
      setActiveTab(<Home sendTap={sendTap} isLoading={loading}/>)
    }
  }

  return (
    <div key={key} style={{width:'100vw', height: '100vh', display: 'flex', justifyContent: 'center'}}>
    {isError && <div className='copyMessage'>Connection error. App will be restarted</div>}
    {(user.is_new_user && onboadingContent < 7) &&
      <Onboarding onEnd={onboardingComplete} setActiveTab={setActiveTab} setActiveIndex={setActiveIndex} content={onboadingContent} setContent={setOnboardingContent}/>
    }

    {(loading === true || (user.is_new_user && onboadingContent < 2) || fetching === true) &&
    <Loading newUser={user.is_new_user}/>
    }
    {activeTab}
    <Menu sendTap={sendTap} setActiveTab={setActiveTab} activeIndex={activeIndex} setActiveIndex={setActiveIndex}/>
    </div>
  );
}

export default App;
