import { useDispatch, useSelector } from 'react-redux';
import { getTimeToRefill, refillEnergy, upgradeEnergy, upgradeMining, upgradeMultitap } from '../../../api/boosterApi';
import BigTab from '../../ui/BigTab/BigTab';
import DecorativeTab from '../../ui/DecorativeTab/DecorativeTab';
import classes from './Boost.module.css'
import { updateBalance, updateEnergy, updateEnergyBoostLevel, updateEnergyRefillTime, updateMiningLevel, updateMiningSecodns, updateTapBoostLevel } from '../../../store/userSlice';
import { useEffect, useState } from 'react';
import { formatNumberToM, secondsToHMS } from '../../../utils';
import ActionModal from '../../modals/ActionModal/ActionModal';
import { useTranslation } from 'react-i18next';

const Boost = () => {

  const token = useSelector((state) => state.token.token);
  const { t } = useTranslation();
  const user = useSelector((state) => state.user);
  const [seconds, setSeconds] = useState(0)
  const [modalOpen, setModalOpen] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    if (token) {
      const fetch = async () => {
        try {
          const response = await getTimeToRefill(token);
          setSeconds(response?.energy_refill_seconds);
        } catch (error) {
          console.error("Ошибка при получении времени для пополнения энергии:", error);
        }
      };
      fetch();
    }
  }, [token]);

  useEffect(() => {
    if (seconds !== 0) {
      const interval = setInterval(() => {
        setSeconds(seconds - 1)
      }, 1000)
  
      return () => clearInterval(interval)
    }
  }, [seconds])


  const energyRefillHandler = async () => {
    const response = await refillEnergy(token)
    if (response?.status === 'success') {
      dispatch(updateEnergy({ energy: response.energy_boost_level }))
      const res = await getTimeToRefill(token)
      setSeconds(res.energy_refill_seconds)
    }
  }
  const multitapUpgradeHandler = async () => {
    const response = await upgradeMultitap(token)
    if (response?.status === 'success') {
      dispatch(updateBalance({ balance: response.balance }))
      dispatch(updateTapBoostLevel({ tap_boost_level: response.tap_boost_level }))
    } else {
      showAction()
    }
  }
  const energyUpgradeHandler = async () => {
    const response = await upgradeEnergy(token)
    if (response?.status === 'success') {
      dispatch(updateBalance({ balance: response.balance }))
      dispatch(updateEnergyBoostLevel({ energy_boost_level: response.energy_boost_level }))
    } else {
      showAction()
    }
  }

  const miningUpgradeHandler = async () => {
    const response = await upgradeMining(token)
    if (response?.status === 'success') {
      dispatch(updateBalance({ balance: response.balance }))
      dispatch(updateMiningLevel({ mining_level: response.mining_level }))
      dispatch(updateMiningSecodns({ mining_claim_seconds: response.mining_claim_seconds }))
    } else {
      showAction()
    }
  }

  const showAction = () => {
    setModalOpen(true)
  }

  return (
    <div className={classes.Boost}>
      {modalOpen && 
        <ActionModal 
          onClose={() => setModalOpen(false)} 
          img={'/assets/coin.svg'} 
          titleTop={t('boost.not_enough')} 
          buttonColor={'#FF6B6B'}
          buttonContent={'Back'}
          onClick={() => setModalOpen(false)}
        />
      }
      <div className={classes.container}>
        <div className={classes.relative}>


        <DecorativeTab 
          width={'55px'} 
          height={'26px'} 
          color={'#00FF88'} 
          bgcolor={'rgba(0, 255, 136, 0.20)'}
          radius={'10px'}
          font={'13px'}
          position={{top: '-5px', left: '-59px'}}
          main={true}
        >
          +1 tap
        </DecorativeTab>
        <DecorativeTab 
          width={'55px'} 
          height={'26px'} 
          color={'#00FF88'} 
          bgcolor={'rgba(0, 255, 136, 0.20)'}
          radius={'10px'}
          font={'13px'}
          position={{bottom: '-30px', left: '114px'}}
          main={true}
        >
          +1 tap
        </DecorativeTab>
        <DecorativeTab 
          width={'88px'} 
          height={'41px'} 
          color={'#00FF88'} 
          bgcolor={'rgba(0, 255, 136, 0.20)'}
          radius={'16px'}
          font={'20px'}
          position={{top: '-30px', right: '-88px'}}
          main={true}
        >
          +1 tap
        </DecorativeTab>


          <h1 className={classes.title}>
            <span className={classes.gradient}>{t('boost.upgrade_your')}<br/>{t('boost.skills')}</span>
            <span className={classes.shadow}>{t('boost.upgrade_your')}<br/>{t('boost.skills')}</span>
            {t('boost.upgrade_your')}<br/>{t('boost.skills')}
          </h1>
        </div>

        <div className={classes.balance}>
          <img src={'/assets/coin.png'} alt='coin' className={classes.coin} style={{width: '39px', height: '39px'}}/>
          <p className={classes.score}>
            <span className={classes.score_gradient}>{Math.floor(user.balance)}</span>
            <span className={classes.score_shadow}>{Math.floor(user.balance)}</span>
            {Math.floor(user.balance)}
          </p>
        </div>

        <div className={classes.boostsContainer}>
          <div className={classes.boosts}>
            <p className={classes.boostTitle}>{t('boost.fdb')}</p>
            <BigTab
              img={'/assets/fullEnergy.png'}
              w={51}
              h={51}
              title={(t('boost.fe'))}
              subtitle={t('boost.cfye')}
              rightContent={seconds !== 0 ? secondsToHMS(seconds) : ''}
              rightContentType={'sub'}
              onClick={energyRefillHandler}
            />
          </div>
          <div className={classes.boosts}>
            <p className={classes.boostTitle}>{t('boost.boosts')}</p>
            <BigTab 
              img={'/assets/multitap.png'}
              w={60}
              h={59}
              title={t('boost.multitap')}
              lvl={user.tap_boost_level - 1}
              subtitle={t('boost.iyt')}
              rightContent={formatNumberToM(1000 * (2**(user.tap_boost_level - 1)))}
              rightContentType={'main'}
              onClick={multitapUpgradeHandler}
            />
            <BigTab 
              img={'/assets/battery.png'}
              w={62}
              h={62}
              lvl={(user.energy_boost_level / 500) - 1}
              title={t('boost.el')}
              subtitle={t('boost.iyel')}
              rightContent={formatNumberToM(1000 * (2**(user.energy_boost_level / 500 - 1)))}
              rightContentType={'main'}
              onClick={energyUpgradeHandler}
            />
            <BigTab 
              img={'/assets/mining.png'}
              w={48}
              h={55}
              lvl={user.mining_level}
              title={t('boost.mining')}
              subtitle={t('boost.ua')}
              rightContent={formatNumberToM(1000 * (2**(user.mining_level)))}
              rightContentType={'main'}
              onClick={miningUpgradeHandler}
            />
            <div className={classes.space}>

            </div>
          </div>
        </div>

      </div>
    </div>
  )
}

export default Boost;