import classes from './Loading.module.css'
import { useEffect, useState } from 'react'
const Loading = ({ newUser }) => {
  const [progress, setProgress] = useState(3)
  useEffect(() => {
    setTimeout(() => setProgress(20), 500)
    setTimeout(() => setProgress(60), 1000)
    setTimeout(() => setProgress(90), 2000)
    setTimeout(() => setProgress(99), 2800)

  }, [])
  return (
    <div className={classes.Loading} style={{background: `url(${process.env.PUBLIC_URL}/assets/loading.jpg) no-repeat center center/cover`}}>
      <h1 className={classes.title}>GEKKO</h1>
      <div className={classes.subcontainer} style={{display: (newUser === true && progress === 90) ? 'none' : 'flex'}}>
        <p className={classes.text}>Loading...</p>
        <div className={classes.bar}>
          <div className={classes.percent} style={{width: `${progress}%`}}></div>
        </div>
      </div>
    </div>
  )
}

export default Loading;


