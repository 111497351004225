import axios from "axios";
import { decode } from "./userApi";
import { path } from "./path";
import { store } from "../store/store";
import { setKey, showError } from "../store/tokenSlice";

export const getReferals = async (token) => {
  try {
    const response = await axios.get(
      `${path}/referrals/my_referrals/${decode(token)}`, 
      {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );

    return response.data;

  } catch (err) {
    console.error('Ошибка получения рефералов', err);
    if (err.response && (err.response.status === 401 || err.response.status === 403)) {
      store.dispatch(showError())
      setTimeout(() => {
        store.dispatch({ type: 'RESET_STATE' });
        store.dispatch(setKey(Date.now()));
      }, 3000)
    }
    return null;
  }
};

export const getLink = async (token) => {
  try {
    const response = await axios.get(
      `${path}/referrals/invite_link/${decode(token)}`, 
      {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      }
    );

    return response.data;

  } catch (err) {
    console.error('Ошибка получения реферальной ссылки', err);
    if (err.response && (err.response.status === 401 || err.response.status === 403)) {
      store.dispatch(showError())
      setTimeout(() => {
        store.dispatch({ type: 'RESET_STATE' });
        store.dispatch(setKey(Date.now()));
      }, 3000)
    }
    return null;
  }
};