import MenuItem from '../MenuItem/MenuItem';
import classes from './Menu.module.css'
import home from '../../../assets/svgs/home.png'
import boost from '../../../assets/svgs/boost.png'
import mine from '../../../assets/svgs/league.png'
import earn from '../../../assets/svgs/earnmn.png'
import friends from '../../../assets/svgs/friends.png'
import homeActive from '../../../assets/svgs/homeActive.png'
import boostActive from '../../../assets/svgs/boostActive.png'
import mineActive from '../../../assets/svgs/leagueActive.png'
import earnActive from '../../../assets/svgs/earnActive.png'
import friendsActive from '../../../assets/svgs/friendsActive.png'
import { useState } from 'react';
import Home from '../../pages/Home/Home';
import Boost from '../../pages/Boost/Boost';
import Mine from '../../pages/Mine/Mine';
import Earn from '../../pages/Earn/Earn';
import Friends from '../../pages/Friends/Friends';
import League from '../../pages/League/League';
import Social from '../../pages/Social/Social';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const Menu = ({sendTap, setActiveTab, activeIndex, setActiveIndex }) => {
  
  const token = useSelector((state) => state.token.token);
  const { t } = useTranslation();
  
  const pages = [
    <Home sendTap={sendTap}/>,
    <Boost/>,
    <League/>,
    <Earn/>,
    <Friends/>,
  ]

  const handleMenuItemClick = (index) => {
    setActiveIndex(index);
    setActiveTab(pages[index])
  };

  return (
    <div className={classes.Menu} onTouchEnd={(e) => e.stopPropagation()}>
        <MenuItem
          title={t('menu.home')}
          img={home}
          imgActive={homeActive}
          clickHandler={() => handleMenuItemClick(0)}
          isActive={activeIndex === 0}
        />
        <MenuItem
          title={t('menu.boost')}
          img={boost}
          imgActive={boostActive}
          clickHandler={() => handleMenuItemClick(1)}
          isActive={activeIndex === 1}
        />
        <MenuItem
          title={t('menu.league')}
          img={mine}
          imgActive={mineActive}
          clickHandler={() => handleMenuItemClick(2)}
          isActive={activeIndex === 2}
        />
        <MenuItem
          title={t('menu.earn')}
          img={earn}
          imgActive={earnActive}
          clickHandler={() => handleMenuItemClick(3)}
          isActive={activeIndex === 3}
        />
        <MenuItem
          title={t('menu.friends')}
          img={friends}
          imgActive={friendsActive}
          clickHandler={() => handleMenuItemClick(4)}
          isActive={activeIndex === 4}
        />
    </div>
  )
}

export default Menu;