import DecorativeTab from '../../ui/DecorativeTab/DecorativeTab';
import classes from './League.module.css'
import UserTab from '../../ui/UserTab/UserTab';
import durov from '../../../assets/svgs/durov.svg'
import LeagueTab from '../../ui/LeagueTab/LeagueTab';
import arrowL from '../../../assets/svgs/arrowL.svg'
import arrowR from '../../../assets/svgs/arrowR.svg'
import { useCallback, useEffect, useMemo, useState } from 'react';
import { formatNumberToM } from '../../../utils';
import { getTop, getWeekly } from '../../../api/leagueApi';
import { useDispatch, useSelector } from 'react-redux';
import { addLeagueData } from '../../../store/leagueSlice';
import { useTranslation } from 'react-i18next';

const League = () => {

  const token = useSelector((state) => state.token.token);
  const { t } = useTranslation();
  const [currentLeague, setCurrentLeague] = useState(0)
  const [type, setType] = useState('total')
  const dispatch = useDispatch();
  const loadedLeagues = useSelector((state) => state.league.loadedLeagues);

  const leagueKey = `${currentLeague}_${type}`;
  const usersArray = loadedLeagues[leagueKey] || [];

  const fetchUsers = useCallback(async () => {
    try {
      if (!loadedLeagues[leagueKey]) {
        let users;
  
        // Получаем данные пользователей в зависимости от типа
        if (type === 'total') {
          users = await getTop(currentLeague + 1, token);
        } else if (type === 'weekly') {
          users = await getWeekly(currentLeague + 1, token);
        }
  
        if (users && users.length) {
          // Определяем поле для сортировки в зависимости от типа
          const balanceField = type === 'total' ? 'balance' : 'balance_tap_weekly';
          
          const sortedUsers = users.sort((a, b) => b[balanceField] - a[balanceField]);
          
          // Отправляем данные в Redux
          dispatch(addLeagueData({ leagueKey, users: sortedUsers }));
        }
      }
    } catch (error) {
      console.error("Ошибка при загрузке данных пользователей:", error);
    }
  }, [currentLeague, type, token, loadedLeagues, leagueKey, dispatch]);
  
  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  const leagues = [
    {
      name: 'Bronze',
      color: '#925E30',
      bgcolor: '24, 14, 4',
      border: '#50240A',
      from: 0,
      to: 24999,
      skin: '/assets/skins/1.png'
    },
    {
      name: 'Silver',
      color: '#FFFFFF',
      bgcolor: '148, 148, 148',
      border: '#BBBBBB',
      from: 25000,
      to: 99999,
      skin: '/assets/skins/2.png'
    },
    {
      name: 'Gold',
      color: '#FFFF02',
      bgcolor: '243, 204, 0',
      border: '#DFBC00',
      from: 100000,
      to: 999999,
      skin: '/assets/skins/3.png'
    },
    {
      name: 'Platinum',
      color: '#DAFFFF',
      bgcolor: '62, 132, 165',
      border: '#357D92',
      from: 1000000,
      to: 1999999,
      skin: '/assets/skins/4.png'
    },
    {
      name: 'Diamond',
      color: '#FFFFFF',
      bgcolor: '137, 171, 171',
      border: '#73837D',
      from: 2000000,
      to: 9999999,
      skin: '/assets/skins/5.png'
    },
    {
      name: 'Sapphire',
      color: '#FFFFFF',
      bgcolor: '35, 35, 216',
      border: '#2323A8',
      from: 10000000,
      to: 49999999,
      skin: '/assets/skins/6.png'
    },
    {
      name: 'Emerald',
      color: '#FFFFFF',
      bgcolor: '66, 113, 100',
      border: '#367918',
      from: 50000000,
      to: 999999999,
      skin: '/assets/skins/7.png'
    },
    {
      name: 'Ruby',
      color: '#FF0303',
      bgcolor: '105, 0, 0',
      border: '#FF0303',
      from: 1000000000,
      to: 14999999999,
      skin: '/assets/skins/8.png'
    },
    {
      name: 'Obsidian',
      color: '#40086B',
      bgcolor: '19, 2, 33',
      border: '#4C1885',
      from: 15000000000,
      to: 19999999999,
      skin: '/assets/skins/9.png'
    },
    {
      name: 'White gold',
      color: '#FFFFFF',
      bgcolor: '218, 218, 218',
      border: '#696663',
      from: 20000000000,
      skin: '/assets/skins/10.png'
    },
    
  ]

  const memoizedUsers = useMemo(() => (
    usersArray.map((user, index) => (
      <UserTab
        key={user.id}
        img={user.profile_photo_url ? user.profile_photo_url : '/assets/defaultAva.png'}
        name={user.username}
        income={type === 'total' ? formatNumberToM(user.balance) : formatNumberToM(user.balance_tap_weekly)}
        rightTitle={t('league.place')}
        rightContent={`#${index + 1}`}
        color={leagues[currentLeague].bgcolor}
        border={leagues[currentLeague].border}
      />
    ))
  ), [usersArray, leagues, currentLeague, type]);

  const handleNext = () => {
    if (leagues[currentLeague + 1]) {
      setCurrentLeague((prev) => prev + 1)
    }
  }

  const handlePrev = () => {
    if (leagues[currentLeague - 1]) {
      setCurrentLeague((prev) => prev - 1)
    }
  }
  
  return (
    <div className={classes.League} >
      <div className={classes.container}>
        <div onClick={handlePrev} className={classes.arrowLeft}>
          <img src={arrowL} alt='arrowL' style={{display: currentLeague === 0 ? 'none' : 'unset'}}/>
        </div>
        <div onClick={handleNext} className={classes.arrowRight}>
          <img src={arrowR} alt='arrowR' style={{display: currentLeague === 9 ? 'none' : 'unset'}}/>
        </div>
        <DecorativeTab 
          width={'55px'} 
          height={'25px'} 
          color={leagues[currentLeague].color} 
          bgcolor={leagues[currentLeague].bgcolor}
          border={leagues[currentLeague].border}
          radius={'10px'}
          font={'13px'}
          position={{top: '77px', left: '38px'}}
        >
          #1
        </DecorativeTab>
        <DecorativeTab 
          width={'43px'} 
          height={'20px'} 
          color={leagues[currentLeague].color} 
          bgcolor={leagues[currentLeague].bgcolor}
          border={leagues[currentLeague].border}
          radius={'8px'}
          font={'13px'}
          position={{top: '241px', left: '45px'}}
        >
          #1
        </DecorativeTab>
        <DecorativeTab 
          width={'88px'} 
          height={'41px'} 
          color={leagues[currentLeague].color} 
          bgcolor={leagues[currentLeague].bgcolor}
          border={leagues[currentLeague].border}
          radius={'16px'}
          font={'24px'}
          position={{top: '61px', right: '10px'}}
        >
          #1
        </DecorativeTab>
        <DecorativeTab 
          width={'55px'} 
          height={'28px'} 
          color={leagues[currentLeague].color} 
          bgcolor={leagues[currentLeague].bgcolor}
          border={leagues[currentLeague].border}
          radius={'10px'}
          font={'13px'}
          position={{top: '248px', right: '20px'}}
        >
          #1
        </DecorativeTab>
        <div className={classes.relative}>

          <h1 className={classes.title} style={{textAlign: 'center'}}>
            {leagues[currentLeague].name}
          </h1>
          <h1 className={classes.title} style={{textAlign: 'center'}}>
            {t('league.l')}
          </h1>
          <div className={classes.skinContainer}>
            <img src={leagues[currentLeague].skin} alt='gekko' className={classes.skin}/>
          </div>
        </div>
        
        <p className={classes.range}>{t('league.f')} {formatNumberToM(leagues[currentLeague].from)} {leagues[currentLeague].to ? `${t('league.t')} ${formatNumberToM(leagues[currentLeague].to)} ${t('league.c')}` : ''}</p>
        
        <div className={classes.stats}>
          <LeagueTab 
            title={t('league.total')} 
            active={false} 
            border={leagues[currentLeague].border} 
            bgcolor={leagues[currentLeague].bgcolor} 
            onClick={() => setType('total')}
            opacity={type === 'weekly' ? '60%' : '100%'}
          />
          <LeagueTab 
            title={t('league.weekly')} 
            active={true} 
            border={leagues[currentLeague].border} 
            bgcolor={leagues[currentLeague].bgcolor}
            onClick={() => setType('weekly')}
            opacity={type === 'total' ? '60%' : '100%'}
          />
        </div>
        <div className={classes.friendsList}>
          <div className={classes.gap}>
            {memoizedUsers}
          </div>
        </div>
      </div>
    </div>
  )
}

export default League;