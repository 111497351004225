import { useState } from 'react';
import Button from '../../ui/Button/Button';
import classes from './ActionModal.module.css'
import { checkTask, getTasks } from '../../../api/taskApi';
import { useDispatch, useSelector } from 'react-redux';
import { updateBalance } from '../../../store/userSlice';
import { setTasks, updateButtonState } from '../../../store/tasksSlice';
import { useTranslation } from 'react-i18next';

const ActionModal = ({ onClose, img, titleTop, titleBot, buttonColor, buttonContent, onClick, id }) => {
  const token = useSelector((state) => state.token.token);

  const { t } = useTranslation();

  const { loadedTasks, dailyTasks, specialTasks } = useSelector((state) => state.tasks);
  const dispatch = useDispatch()

  const [currentColor, setCurrentColor] = useState('#00FF88')
  const [currentContent, setCurrentContent] = useState(t('action.c'))

  const clickHandler = async (id, token) => {
    const tg = window.Telegram ? window.Telegram.WebApp : null;
    const currentTask = loadedTasks.find(task => task.id === id)
    const response = await checkTask(id, token)
    if (response[0].status === 'success') {
      dispatch(updateButtonState({ id: id, status: 'ready_to_claim' })); // Обновляем статус на 'ready_to_claim'
      onClose()
    } else {
      tg.openTelegramLink(currentTask.link);
      setCurrentColor('#FF6B6B')
      setCurrentContent(t('action.ns'))
    }
  }

  return (
    <div className={classes.ActionModal} onClick={onClose}>
      <div className={classes.modalContent} onClick={(e) => e.stopPropagation()}>
        <div className={classes.shadow}></div>
          <div className={classes.info}>
            {img && <img src={img} alt={'img'} className={classes.img}/>}
            <p className={classes.title}>{titleTop}<span className={classes.gradient}>{titleTop}</span></p>
            <p className={classes.title} style={{marginBottom: '15px'}}>{titleBot}<span className={classes.gradient}>{titleBot}</span></p>
            <Button
              blur={false}
              width={'186px'} 
              height={'45px'} 
              background={buttonColor ? buttonColor : currentColor} 
              border={'18px'}
              font={'19px'} 
              onClick={onClick ? onClick : () => clickHandler(id, token)}
            >
              {buttonContent ? buttonContent : currentContent}
            </Button>
          </div>
      </div>
    </div>
  )
}

export default ActionModal;