import React from 'react';
import classes from './Spinner.module.css'; // Импортируйте стили

const Spinner = ({ width, height, b, bt, margin }) => {
  return (
    <div className={classes.spinner} style={{ width: width, height: height, border: b, borderTop: bt, margin: margin }}></div>
  )
  
};

export default Spinner;
